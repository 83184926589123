const serverUrl = 'https://data.estateliga.ru';
const feedUrl = 'https://data.estateliga.ru/xml/';

export default {
    serverUrl,
    photoUploadUrl: serverUrl + '/uploadPhotosHandler',
    feedUrl,
    pushNotifications: {
        operator: 'onesignal',
        appId: '85ed58cc-8b98-4b8a-beb3-ddd7f99995f5'
    },
    crmOutgoingCalls: false,
    callsServerUrl: 'https://data.agentbase.ru:2935/', // 'https://data.estateliga.ru:2988/',
    whatsappServerUrl: 'https://wa-stream.topstore.pro:',
    whatsappDefaultPort: 50000,
    whatsappFilesUrl: 'https://wa-files.topstore.pro/'
};
