// @flow
export default {
    dailyCalls: {
        red: 10,
        green: 20
    },
    weekCalls: {
        red: 70,
        green: 140
    },
    lastOutgoingCallTimes: {
        warning: 604800, // 7 days
        ban: 1209600 // 14 days
    }
};
